import React from 'react'
import "../Dashboard/dashboard.scss";

import Resumes from '../Resumes.js';

 const Dashboard = () => {
  return (
    <div className='dashboard'>
        <Resumes/>
    </div>

  )
}

export default Dashboard;