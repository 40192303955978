import { Grid, Typography } from "@material-ui/core";
import "../component/welcome.scss"
import JobsL from "../assets/jobsL.json";
import Lottie from "lottie-react";
import Typical from "react-typical";
const Welcome = (props) => {
  return (
    <Grid
      container
      item
      direction="column"
      alignItems="center"
      justify="center"
      style={{ padding: "30px", minHeight: "93vh" }}
    >
      <Grid item>
        <div className="welcome">
        <div className="titleL">
        <div className="titleM">
        Welcome to Recruitment Assistant Portal
        </div>
        <div className="spaced">
          A portal that's{' '}
          <Typical className ="animat"
            loop={Infinity}
            
            wrapper="b"
            steps={[
              'beginner friendly',
              1000,
              'easy to use',
              1000,
              'all in one',
              1000,
            ]}
          />
          
        </div>
        </div>
        
        
        <div className="MyLogo">
        <Lottie loop={true} animationData={JobsL}/>
        </div>
        </div>
      </Grid>
    </Grid>
  );
};

export const ErrorPage = (props) => {
  return (
    <Grid
      container
      item
      direction="column"
      alignItems="center"
      justify="center"
      style={{ padding: "30px", minHeight: "93vh" }}
    >
      <Grid item>
        <Typography variant="h2">Error 404</Typography>
      </Grid>
    </Grid>
  );
};

export default Welcome;
