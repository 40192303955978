import Header from "../component/Header/Header"
import Body from "../component/Body/Body"
import "../component/Body/Body.module.css"
import "../component/Header/Header.module.css"

export default function Resumes() {
    return(
    <>
      <Header/>
      <Body />
    </>
    ) 

  }